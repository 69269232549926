<template>
  <div class="navigation-illustration">
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 799 1000" width="799" height="1000">
      <g id="moon">
        <path
          id="oval"
          class="s1"
          d="m399.2 1369.2c-278.8 0-504.2-188-504.2-420.6 0-232.6 225.4-420.6 504.2-420.6 278.8 0 504.2 188 504.2 420.6 0 232.6-225.4 420.6-504.2 420.6z"
        />
        <g id="craters">
          <path
            id="hole"
            class="s2"
            d="m527.3 630.5c-7.1 16.9 10.4 40.3 38.9 52.2 28.6 11.9 57.5 7.9 64.5-9.1 7.1-16.9-10.3-40.3-38.9-52.2-28.6-11.9-57.4-7.9-64.5 9.1z"
          />
          <path
            id="holeshadow"
            class="s3"
            d="m580.5 636.1c-20.8-8.7-41.7-8.9-54.3-1.9q0.3-1.9 1.1-3.7c7.1-17 35.9-21 64.5-9.1 28.6 11.9 46 35.3 38.9 52.2-1.9 4.7-5.4 8.3-10.2 10.9 3.4-16.4-13.5-37.4-40-48.4z"
          />
          <path
            id="hole"
            class="s2"
            d="m639.8 701.8c-2.8 18.1 19.7 36.7 50.2 41.4 30.6 4.8 57.7-6 60.5-24.1 2.9-18.2-19.6-36.7-50.2-41.5-30.6-4.8-57.7 6-60.5 24.2z"
          />
          <path
            id="holeshadow"
            class="s3"
            d="m692.8 694.5c-22.3-3.4-42.7 1.4-53.2 11.2q-0.1-2 0.2-3.9c2.8-18.2 29.9-29 60.5-24.2 30.6 4.8 53.1 23.3 50.2 41.5-0.7 4.9-3.3 9.3-7.3 13-0.7-16.8-22.1-33.1-50.4-37.6z"
          />
          <path
            id="hole"
            class="s2"
            d="m447 725.5c-4.9 24.4 24.5 50.9 65.6 59.1 41.2 8.3 78.5-4.8 83.4-29.2 4.9-24.4-24.5-50.9-65.6-59.1-41.2-8.3-78.5 4.8-83.4 29.2z"
          />
          <path
            id="hole"
            class="s2"
            d="m462.1 658.2c-1.4 7.1 9.1 15.2 23.4 18.1 14.3 2.8 27.1-0.6 28.5-7.7 1.4-7.1-9-15.2-23.4-18-14.3-2.9-27 0.5-28.5 7.6z"
          />
          <path
            id="hole"
            class="s2"
            d="m376 689.7c-1.6 10.3 8.8 20.4 23.2 22.6 14.4 2.3 27.4-4.2 29-14.4 1.6-10.2-8.8-20.3-23.2-22.6-14.5-2.2-27.4 4.2-29 14.4z"
          />
          <path
            id="hole"
            class="s2"
            d="m363.5 754.3c-1.8 11.4 15.4 23.6 38.4 27.2 23 3.6 43-2.8 44.8-14.2 1.8-11.4-15.4-23.6-38.4-27.2-23-3.5-43 2.8-44.8 14.2z"
          />
          <path
            id="hole"
            class="s2"
            d="m500.5 595.9c-0.7 5.7 8.2 11.5 19.8 12.9 11.6 1.3 21.5-2.2 22.2-8 0.7-5.8-8.2-11.5-19.8-12.9-11.6-1.3-21.6 2.2-22.2 8z"
          />
          <path
            id="hole"
            class="s2"
            d="m648.8 630.3c-3.6 6.7 2.9 17.1 14.5 23.2 11.6 6.2 23.8 5.7 27.4-1 3.6-6.7-2.9-17.2-14.5-23.3-11.6-6.1-23.8-5.7-27.4 1.1z"
          />
          <path
            id="hole"
            class="s2"
            d="m615 756.4c-2.1 10.6 10.9 22 29 25.7 18.1 3.6 34.5-2 36.6-12.5 2.2-10.6-10.8-22-29-25.7-18.1-3.6-34.5 2-36.6 12.5z"
          />
          <path
            id="holeshadow"
            class="s3"
            d="m519.2 718.8c-29.9-6-57.8-0.7-72.7 12q0-2.6 0.5-5.3c4.9-24.4 42.2-37.5 83.4-29.2 41.1 8.2 70.5 34.7 65.6 59.1-1.3 6.6-5.1 12.4-10.7 17.2 0.1-22.8-27.9-46.1-66.1-53.8z"
          />
          <path
            id="hole"
            class="s2"
            d="m338.5 623c1.8 19.9 30.5 33.5 64.1 30.4 33.5-3.1 59.3-21.7 57.4-41.6-1.8-19.9-30.5-33.5-64.1-30.4-33.5 3.1-59.2 21.7-57.4 41.6z"
          />
          <path
            id="holeshadow"
            class="s3"
            d="m392.5 601.3c-24.4 2.2-44.7 12.7-53.2 25.9q-0.6-2-0.8-4.2c-1.8-19.9 23.9-38.5 57.4-41.6 33.6-3.1 62.3 10.5 64.1 30.4 0.5 5.4-1 10.7-4.2 15.7-5.2-17.6-32.2-29.1-63.3-26.2z"
          />
          <path
            id="hole"
            class="s2"
            d="m276 630.5c7.1 16.9-10.3 40.3-38.9 52.2-28.6 11.9-57.4 7.9-64.5-9.1-7.1-16.9 10.4-40.3 38.9-52.2 28.6-11.9 57.5-7.9 64.5 9.1z"
          />
          <path
            id="holeshadow"
            class="s3"
            d="m222.9 636.1c20.7-8.7 41.7-8.9 54.3-1.9q-0.4-1.9-1.2-3.7c-7-17-35.9-21-64.5-9.1-28.5 11.9-46 35.3-38.9 52.2 1.9 4.7 5.5 8.3 10.2 10.9-3.3-16.4 13.6-37.4 40.1-48.4z"
          />
          <path
            id="hole"
            class="s2"
            d="m163.5 701.8c2.9 18.1-19.6 36.7-50.2 41.4-30.6 4.8-57.7-6-60.5-24.1-2.8-18.2 19.7-36.7 50.3-41.5 30.5-4.8 57.6 6 60.4 24.2z"
          />
          <path
            id="holeshadow"
            class="s3"
            d="m110.6 694.5c22.2-3.4 42.6 1.4 53.2 11.2q0-2-0.3-3.9c-2.8-18.2-29.9-29-60.4-24.2-30.6 4.8-53.1 23.3-50.3 41.5 0.8 4.9 3.4 9.3 7.3 13 0.7-16.8 22.1-33.1 50.5-37.6z"
          />
          <path
            id="hole"
            class="s2"
            d="m356.3 725.5c4.9 24.4-24.5 50.9-65.6 59.1-41.1 8.3-78.5-4.8-83.4-29.2-4.8-24.4 24.5-50.9 65.7-59.1 41.1-8.3 78.4 4.8 83.3 29.2z"
          />
          <path
            id="hole"
            class="s2"
            d="m341.2 658.2c1.4 7.1-9 15.2-23.4 18.1-14.3 2.8-27-0.6-28.5-7.7-1.4-7.1 9.1-15.2 23.4-18 14.3-2.9 27.1 0.5 28.5 7.6z"
          />
          <path
            id="hole"
            class="s2"
            d="m302.9 595.9c0.6 5.7-8.2 11.5-19.8 12.9-11.7 1.3-21.6-2.2-22.3-8-0.7-5.8 8.2-11.5 19.8-12.9 11.6-1.3 21.6 2.2 22.3 8z"
          />
          <path
            id="hole"
            class="s2"
            d="m154.5 630.3c3.6 6.7-2.9 17.1-14.5 23.2-11.5 6.2-23.8 5.7-27.4-1-3.5-6.7 3-17.2 14.5-23.3 11.6-6.1 23.9-5.7 27.4 1.1z"
          />
          <path
            id="hole"
            class="s2"
            d="m188.3 756.4c2.2 10.6-10.8 22-29 25.7-18.1 3.6-34.5-2-36.6-12.5-2.1-10.6 10.9-22 29-25.7 18.1-3.6 34.5 2 36.6 12.5z"
          />
          <path
            id="holeshadow"
            class="s3"
            d="m284.1 718.8c30-6 57.9-0.7 72.7 12q0-2.6-0.5-5.3c-4.9-24.4-42.2-37.5-83.3-29.2-41.2 8.2-70.5 34.7-65.7 59.1 1.4 6.6 5.1 12.4 10.7 17.2-0.1-22.8 28-46.1 66.1-53.8z"
          />
        </g>
      </g>
      <g id="astronaut">
        <g id="oxygen">
          <path
            id="tank"
            class="s4"
            d="m287 444.8l10.1 47.1c3.1 14.6-6.2 29-20.8 32.2l-37.6 8c-14.6 3.1-29-6.2-32.1-20.8l-10.1-47.1c-3.2-14.7 6.1-29 20.8-32.2l37.5-8c14.7-3.2 29 6.1 32.2 20.8z"
          />
          <path id="stripe" class="s5" d="m292 468.3l1.5 7-9.4 2-1.4-6.9z" />
          <path id="stripe" class="s5" d="m294.7 481.1l1.5 6.9-9.3 2-1.5-6.9z" />
          <path id="stripe" class="s5" d="m204.3 500.5l1.5 6.9 9.3-2-1.5-6.9z" />
          <path id="stripe" class="s5" d="m202 490.1l1.5 7 9.4-2.1-1.5-6.9z" />
        </g>
        <g id="human">
          <path
            id="lefthand"
            class="s6"
            d="m307.4 395.6l-0.6 14.6-4.3-0.1-0.2 3.5-4.4-0.2 0.1-1.7-4.4-0.2 0.1-1.8-4.4-0.1 0.5-14.6c0-1.2 1.1-2.1 2.3-2.1 0.6 0 1.1 0.3 1.5 0.7 0.4 0.4 0.6 1 0.6 1.6l0.1-4.5c0.1-1.2 1.1-2.2 2.3-2.1 0.6 0 1.2 0.2 1.5 0.7 0.4 0.4 0.7 0.9 0.6 1.5v1.8c0-1.2 1-2.2 2.2-2.1 0.6 0 1.2 0.3 1.6 0.7 0.4 0.4 0.6 0.9 0.6 1.5l-0.1 2.8c0-1.2 1-2.2 2.2-2.1 0.6 0 1.2 0.2 1.6 0.6 0.3 0.5 0.6 1 0.6 1.6z"
          />
          <path
            id="righthand"
            class="s6"
            d="m190.8 538l-3.4-14.2 4.3-1-0.8-3.5 4.3-1 0.4 1.7 4.2-1 0.5 1.7 4.2-1 3.4 14.2c0.3 1.2-0.4 2.3-1.6 2.6-0.6 0.2-1.2 0.1-1.7-0.2-0.4-0.3-0.8-0.8-0.9-1.4l1 4.4c0.3 1.2-0.5 2.3-1.6 2.6-0.6 0.2-1.2 0-1.7-0.2-0.5-0.3-0.8-0.8-1-1.4l-0.4-1.7c0.3 1.2-0.4 2.4-1.6 2.6-0.6 0.2-1.2 0.1-1.7-0.2-0.4-0.3-0.8-0.8-1-1.4l-0.6-2.7c0.3 1.2-0.4 2.4-1.6 2.7-0.6 0.1-1.2 0-1.7-0.3-0.5-0.3-0.8-0.7-1-1.3z"
          />
          <path id="leftarm" class="s7" d="m274.3 470.9l-8.1-18.1h-0.1c0.9-0.4 21.6-10.7 21.9-46l19.9 0.2c-0.4 48.6-32.2 63.3-33.6 63.9z" />
          <path
            id="rightarm"
            class="s7"
            d="m206.9 523.8l-19.2 5.3c-7.1-25.6 1.4-42.1 9.8-51.5 8.9-9.8 19.3-13.6 20.4-14l6.5 18.8h0.1c-0.1 0-7.1 2.6-12.6 9.1-7.1 8.1-8.7 19-5 32.3z"
          />
          <path id="path" class="s5" d="m271.8 441.3l1.2 5.5c-0.3-1.2-1.2-2.3-2.6-3.2 1-0.9 1.5-1.6 1.4-2.3z" />
          <path
            id="flap"
            class="s8"
            d="m261.8 521.9c-0.1 3.9 0.3 8.3 1.2 12.9q-2-0.3-4 0.1-2 0.4-3.8 1.5c-1-4.5-2.4-8.7-4.1-12.2q2.6-0.4 5.4-1 2.7-0.6 5.3-1.3z"
          />
          <path id="path" class="s9" d="m289.6 580.9q-0.3 0.4-0.6 0.8 0-0.6 0-1.3 0.3 0.3 0.6 0.5z" />
          <path id="path" class="s8" d="m250.7 589.9q-0.4-0.2-0.8-0.5 0.2-0.3 0.3-0.7 0.3 0.6 0.5 1.2z" />
          <path id="path" class="s10" d="m217.4 520.4v1q-0.1-0.5 0-1z" />
          <path id="path" class="s11" d="m291.3 505.6l-0.3-0.9q0.2 0.4 0.3 0.9z" />
          <path
            id="helmet"
            class="s23"
            d="m274.5 411.2c2.1 10.2-0.1 20.3-5.4 28.6-1.7-0.4-4-0.5-6.8-0.4 2.3-7.9 2.8-16.4 1.4-23-3.3-15.6-19-25.5-34.9-22.1-15.9 3.4-26.1 18.9-22.7 34.5 1.4 6.6 5.4 14.1 10.7 20.3-2.6 1.1-4.7 2.2-6.1 3.2-8.2-5.4-14.4-13.7-16.6-23.9-4.5-21 9.8-41.9 32-46.7 22.2-4.8 43.9 8.5 48.4 29.5z"
          />
          <path
            id="path"
            class="s13"
            d="m263.7 416.4c1.4 6.6 0.9 15.1-1.4 23q-0.3 0.8-0.5 1.6c-0.8 2.4-1.8 4.7-2.9 6.9l-1.4 0.3c1-2.4 1.8-4.8 2.5-7.4q0.1-0.6 0.3-1.3c1.2-5.7 1.4-11.6 0.4-16.3-1.1-5.1-3.7-9.5-7.3-12.8-5.8-5.3-14.1-7.9-22.4-6.1-11.7 2.5-19.7 12.6-20 23.8q0 2.8 0.6 5.6c1 4.7 3.6 10 7 14.7q-0.9 0.4-1.8 0.7c-5.3-6.2-9.3-13.7-10.7-20.3-3.4-15.6 6.8-31.1 22.7-34.5 15.9-3.4 31.6 6.5 34.9 22.1z"
          />
          <path
            id="face"
            class="s6"
            d="m260.7 423.2c1 4.7 0.8 10.6-0.4 16.3q-0.2 0.7-0.3 1.3c-0.7 2.6-1.5 5-2.5 7.4q-0.3 0.7-0.7 1.3-0.9 2-2.1 3.8c-3.7 2.1-7.8 3.7-12.2 4.7-4.5 0.9-8.9 1.1-13.1 0.7q-1.8-1.1-3.5-2.5-0.6-0.5-1.1-1c-1.9-1.7-3.7-3.6-5.3-5.7q-0.4-0.5-0.9-1.1c-3.4-4.7-6-10-7-14.7-2.9-13.3 5.8-26.5 19.4-29.4 13.5-2.9 26.8 5.6 29.7 18.9z"
          />
          <path id="path" class="s13" d="m262.6 447.5q-2.9 2.7-6.3 4.8 1.1-1.5 2-3.2 2.3-0.8 4.3-1.6z" />
          <path id="path" class="s13" d="m227.6 458.5q-4-0.5-7.8-1.8 2.2-0.1 4.6-0.4 1.5 1.2 3.2 2.2z" />
          <path id="path" class="s13" d="m258.3 449.1q-0.9 1.7-2 3.2-0.8 0.5-1.6 1 1.2-1.8 2.1-3.8 0.8-0.2 1.5-0.4z" />
          <path id="path" class="s13" d="m229.4 458.7q-0.9-0.1-1.8-0.2-1.7-1-3.2-2.2 0.7 0 1.5-0.1 1.7 1.4 3.5 2.5z" />
          <path
            id="path"
            class="s5"
            d="m270.4 443.6c1.4 0.9 2.3 2 2.6 3.2 0.4 2-0.7 4.2-3.1 6.4-4.7 4.5-14.4 8.9-26 11.4-11.7 2.5-22.3 2.4-28.4 0.3-3-1-5-2.6-5.4-4.6l-1.2-5.5c-0.1-0.7 0.5-1.6 1.8-2.5q1.3 0.8 2.6 1.5 2.7 1.5 5.8 2.6 0.4 0.2 0.7 0.3 3.8 1.3 7.8 1.8 0.9 0.1 1.8 0.2c4.2 0.4 8.6 0.2 13.1-0.7 4.4-1 8.5-2.6 12.2-4.7q0.8-0.5 1.6-1 3.4-2.1 6.3-4.8 0.3-0.3 0.6-0.5 2.3-2.3 4.2-4.8 0.9-1.2 1.7-2.4c1.6 0.3 2.5 0.8 2.7 1.5 0.1 0.7-0.4 1.4-1.4 2.3z"
          />
          <path id="path" class="s14" d="m269.1 439.8q-0.8 1.2-1.7 2.4c-1.6-0.6-3.5-1-5.6-1.2q0.2-0.8 0.5-1.6c2.8-0.1 5.1 0 6.8 0.4z" />
          <path id="path" class="s14" d="m217.9 450.4c-1.8 1.1-3.4 2.3-4.6 3.4q-1.3-0.7-2.6-1.5c1.4-1 3.5-2.1 6.1-3.2q0.5 0.7 1.1 1.3z" />
          <path id="path" class="s13" d="m223.3 455.5l-4.2 0.9q-3.1-1.1-5.8-2.6c1.2-1.1 2.8-2.3 4.6-3.4 1.7 1.9 3.5 3.6 5.4 5.1z" />
          <path id="path" class="s13" d="m267.4 442.2q-1.9 2.5-4.2 4.8l-4.3 0.9c1.1-2.2 2.1-4.5 2.9-6.9 2.1 0.2 4 0.6 5.6 1.2z" />
          <path id="path" class="s15" d="m263.2 447q-0.3 0.2-0.6 0.5-2 0.8-4.3 1.6 0.3-0.6 0.6-1.2z" />
          <path id="path" class="s13" d="m224.4 456.3q-2.4 0.3-4.6 0.4-0.3-0.1-0.7-0.3l4.2-0.9q0.5 0.4 1.1 0.8z" />
          <path
            id="path"
            class="s13"
            d="m224.8 455.2l-1.5 0.3c-1.9-1.5-3.7-3.2-5.4-5.1q-0.6-0.6-1.1-1.3 0.9-0.3 1.8-0.7 0.5 0.6 0.9 1.1c1.6 2.1 3.4 4 5.3 5.7z"
          />
          <path id="path" class="s13" d="m258.9 447.9q-0.3 0.6-0.6 1.2-0.7 0.2-1.5 0.4 0.4-0.6 0.7-1.3z" />
          <path id="path" class="s13" d="m225.9 456.2q-0.8 0.1-1.5 0.1-0.6-0.4-1.1-0.8l1.5-0.3q0.5 0.5 1.1 1z" />
          <path
            id="rightleg"
            class="s8"
            d="m247 577.6c0.8 4 1.9 7.7 3.2 11.1q-0.1 0.4-0.3 0.7c-4.2-2.3-9.4-3.6-14.5-2.5-3.3 0.7-6.1 2.3-8.5 4.3-5.3-7.7-8.8-20.8-8.9-35.7-0.1-11.8 2.1-22.5 5.5-30.3 6.1 1.3 16.2 1 27.6-1 1.7 3.5 3.1 7.7 4.1 12.2-8.6 5.5-12.3 22.6-8.2 41.2z"
          />
          <path
            id="leftleg"
            class="s8"
            d="m311.3 573.1c-3-0.9-6.3-1.1-9.5-0.4-5.2 1.1-9.4 4.3-12.2 8.2q-0.3-0.2-0.6-0.5c-0.2-3.7-0.7-7.5-1.5-11.4-4.1-18.7-14.4-32.8-24.5-34.2-0.9-4.6-1.3-9-1.2-12.9 11.2-2.8 20.5-6.7 25.5-10.4 6.4 5.7 12.7 14.6 17.5 25.4 6 13.6 8.1 27 6.5 36.2z"
          />
          <path
            id="rightsole"
            class="s5"
            d="m258.3 599.1c0.3 1.3 0.2 2.5-0.3 3.5-1.9 4.1-9 6.1-17.6 8-10.7 2.3-19.6 3.4-21-3.2l9.7-0.7c6.9-0.6 13.6-2 20.1-4.3l6.8-2.5z"
          />
          <path
            id="rightshoe"
            class="s7"
            d="m258.3 599.1l-2.3 0.8-6.8 2.5c-6.5 2.3-13.2 3.7-20.1 4.3l-9.7 0.7c-0.9-4.5 2-11.6 7.5-16.2 2.4-2 5.2-3.6 8.5-4.3 5.1-1.1 10.3 0.2 14.5 2.5q0.4 0.3 0.8 0.5c4.1 2.5 6.9 6 7.6 9.2z"
          />
          <path
            id="leftsole"
            class="s5"
            d="m324.8 584.9c1.4 6.5-7.2 9.1-17.9 11.4-8.6 1.8-15.9 2.9-19.3 0-0.8-0.8-1.4-1.8-1.7-3.1l2.4-0.2 7.3-0.6c6.8-0.5 13.5-2 20-4.3z"
          />
          <path
            id="leftshoe"
            class="s7"
            d="m324.8 584.9l-9.2 3.2c-6.5 2.3-13.2 3.8-20 4.3l-7.3 0.6-2.4 0.2c-0.7-3.2 0.5-7.5 3.1-11.5q0.3-0.4 0.6-0.8c2.8-3.9 7-7.1 12.2-8.2 3.2-0.7 6.5-0.5 9.5 0.4 6.9 1.9 12.5 7.2 13.5 11.8z"
          />
          <g id="body">
            <path
              id="shirt"
              fill-rule="evenodd"
              class="s7"
              d="m290.6 508.3c-0.6 1-1.8 2.1-3.3 3.2-5 3.7-14.3 7.6-25.5 10.4q-2.6 0.7-5.3 1.3-2.8 0.6-5.4 1c-11.4 2-21.5 2.3-27.6 1-1.8-0.4-3.3-0.9-4.3-1.6-1.1-0.7-1.7-1.9-1.8-3.2l-1.9-55.5c6.1 2.1 16.7 2.2 28.4-0.3 11.6-2.5 21.3-6.9 26-11.4l21.1 51.5c0.4 1.2 0.3 2.5-0.4 3.6zm-25.7-6.5c3.6-0.8 5.9-4.3 5.1-7.9l-3.5-16.5c-0.8-3.6-4.3-5.9-7.9-5.1l-25 5.3c-3.5 0.8-5.8 4.3-5 7.9l3.5 16.5c0.8 3.6 4.3 5.9 7.9 5.1z"
            />
            <path
              id="path"
              fill-rule="evenodd"
              class="s16"
              d="m266.5 477.4l3.5 16.5c0.8 3.6-1.5 7.1-5.1 7.9l-24.9 5.3c-3.6 0.8-7.1-1.5-7.9-5.1l-3.5-16.5c-0.8-3.6 1.5-7.1 5-7.9l25-5.3c3.6-0.8 7.1 1.5 7.9 5.1zm-4.4 3.1c-0.6-2.7-3.2-4.4-5.9-3.8l-18.6 4c-2.7 0.6-4.4 3.2-3.8 5.9l2.6 12.3c0.6 2.7 3.3 4.4 5.9 3.8l18.7-4c2.6-0.5 4.3-3.2 3.8-5.9z"
            />
            <path
              id="path"
              fill-rule="evenodd"
              class="s7"
              d="m262.1 480.5l2.7 12.3c0.5 2.7-1.2 5.4-3.8 5.9l-18.7 4c-2.6 0.6-5.3-1.1-5.9-3.8l-2.6-12.3c-0.6-2.7 1.1-5.3 3.8-5.9l18.6-4c2.7-0.6 5.3 1.1 5.9 3.8zm-5.3 7.6c-0.7-3.2-3.9-5.3-7.1-4.6l-3.3 0.7c-3.3 0.7-5.3 3.9-4.6 7.1 0.7 3.2 3.9 5.3 7.1 4.6l3.3-0.7c3.2-0.7 5.3-3.9 4.6-7.1z"
            />
            <path
              id="path"
              class="s17"
              d="m256.8 488.1c0.7 3.2-1.4 6.4-4.6 7.1l-3.3 0.7c-3.2 0.7-6.4-1.4-7.1-4.6-0.7-3.2 1.3-6.4 4.6-7.1l3.3-0.7c3.2-0.7 6.4 1.4 7.1 4.6z"
            />
            <path
              id="path"
              class="s16"
              d="m256.5 486.8c0.7 3.2-1.4 6.4-4.6 7.1l-3.3 0.7c-3.2 0.7-6.4-1.4-7.1-4.6-0.7-3.2 1.4-6.4 4.6-7.1l3.3-0.7c3.2-0.7 6.4 1.4 7.1 4.6z"
            />
            <path
              id="path"
              class="s17"
              d="m275.6 490.6c-1.4 0.4-2.9-0.4-3.3-1.8l-2.8-10.4c-0.4-1.4 0.4-2.8 1.8-3.2 1.4-0.4 2.9 0.4 3.3 1.8l2.8 10.4c0.4 1.4-0.4 2.9-1.8 3.2z"
            />
            <path
              id="path"
              class="s17"
              d="m225.7 501.3c1.4-0.2 2.4-1.5 2.2-3l-1.7-10.6c-0.2-1.4-1.5-2.4-3-2.2-1.4 0.2-2.4 1.6-2.2 3l1.7 10.6c0.2 1.5 1.5 2.5 3 2.2z"
            />
          </g>
          <path
            id="rightey"
            class="s18"
            d="m226.6 430.9c0.4 1.8-0.7 3.6-2.5 4-1.8 0.4-3.6-0.8-4-2.6-0.4-1.8 0.8-3.6 2.6-3.9 1.8-0.4 3.5 0.7 3.9 2.5z"
          />
          <path id="leftey" class="s18" d="m245.8 426.8c0.4 1.8 2.2 3 4 2.6 1.8-0.4 3-2.2 2.6-4-0.4-1.8-2.2-3-4-2.6-1.8 0.4-3 2.2-2.6 4z" />
          <path
            id="nose"
            class="s19"
            d="m242.9 433.9c0.5 2.2-1.5 4.5-4.5 5.2-2.9 0.6-5.7-0.7-6.2-2.9-0.5-2.3 0.9-7.5 3.9-8.2 2.9-0.6 6.3 3.7 6.8 5.9z"
          />
          <path
            id="mouth"
            class="s18"
            d="m240 446.4c-3.9 0.8-7.2-0.2-7.6-2.4-0.2-0.5 0.2-1 0.7-1.2 0.6-0.1 1.1 0.3 1.2 0.8 0.2 0.7 2.3 1.5 5.3 0.8 2.9-0.6 4.6-2.1 4.4-2.9-0.1-0.5 0.3-1 0.8-1.2 0.5-0.1 1.1 0.3 1.2 0.8 0.4 2.2-2.1 4.4-6 5.3z"
          />
          <path
            id="leftcheek"
            class="s20"
            d="m248.1 435.6c0.3 1.3 1.5 2.1 2.8 1.9 1.3-0.3 2.1-1.6 1.8-2.9-0.2-1.2-1.5-2-2.8-1.8-1.2 0.3-2.1 1.6-1.8 2.8z"
          />
          <path
            id="rightcheek"
            class="s20"
            d="m223.5 440.9c0.3 1.3 1.6 2.1 2.8 1.8 1.3-0.3 2.1-1.5 1.9-2.8-0.3-1.3-1.6-2.1-2.8-1.8-1.3 0.3-2.1 1.5-1.9 2.8z"
          />
          <path
            id="leftpatch"
            class="s10"
            d="m305.6 545.6c0.9 4.2-2 8.4-6.4 9.3-4.5 1-8.8-1.7-9.7-5.9-0.9-4.2 2-8.4 6.4-9.3 4.4-1 8.8 1.7 9.7 5.9z"
          />
          <path
            id="rightpatch"
            class="s10"
            d="m238 560.1c0.9 4.2-1.9 8.4-6.4 9.3-4.4 1-8.7-1.7-9.6-5.9-1-4.2 1.9-8.4 6.3-9.3 4.5-1 8.8 1.7 9.7 5.9z"
          />
          <path
            id="hair"
            class="s21"
            d="m253.4 410.4l-0.3 2c0 0-1 4-6.1 4.1-5 0-15.4-9.3-15.4-9.3 0 0 0.5 8.9-3.1 6.8-3.1-1.9-4.9-3.9-5.2-4.2 0.4 0.6 3.5 5.4-0.1 10.9-2.9 4.4-9.2 6.6-12.2 7.4 0.3-11.2 8.3-21.3 20-23.8 8.3-1.8 16.6 0.8 22.4 6.1z"
          />
        </g>
      </g>
      <g id="flag">
        <path id="flag" class="s5" d="m171.1 506l-60.2 13.3-10.9-49.2 60.2-13.3c6.5-1.5 12.9 2.6 14.3 9.1l5.7 25.9c1.4 6.4-2.6 12.8-9.1 14.2z" />
        <path
          id="flagpole"
          class="s7"
          d="m137.5 641.1l-0.6 0.1c-1.6 0.4-3.2-0.7-3.5-2.3l-37.3-168.5c-0.4-1.6 0.7-3.2 2.3-3.6l0.6-0.1c1.6-0.4 3.2 0.7 3.5 2.3l37.3 168.5c0.3 1.6-0.7 3.2-2.3 3.6z"
        />
      </g>
      <g id="stars">
        <g id="planet">
          <path
            id="circle"
            class="s22"
            d="m730 421.4c0 40.1-32.5 72.6-72.6 72.6-40.1 0-72.6-32.5-72.6-72.6 0-40.1 32.5-72.6 72.6-72.6 40.1 0 72.6 32.5 72.6 72.6z"
          />
          <path
            id="shadow"
            class="s23"
            d="m657.4 348.8q-0.7 0-1.5 0c17 13.3 27.9 34 27.9 57.2 0 39.6-31.7 71.7-71.1 72.6 12.3 9.6 27.8 15.4 44.7 15.4 40.1 0 72.6-32.5 72.6-72.6 0-40.1-32.5-72.6-72.6-72.6z"
          />
        </g>
        <g id="planet">
          <path
            id="circle"
            class="s6"
            d="m87.4 336c4.9 16.9 22.5 26.6 39.3 21.7 16.9-4.8 26.6-22.4 21.8-39.3-4.9-16.8-22.5-26.5-39.4-21.7-16.8 4.9-26.5 22.5-21.7 39.3z"
          />
          <path
            id="shadow"
            class="s24"
            d="m109.1 296.7q0.4-0.1 0.7-0.1c-5.5 7.6-7.6 17.6-4.8 27.4 4.8 16.6 22 26.3 38.7 21.8-4 5.6-9.9 9.9-17 11.9-16.8 4.9-34.4-4.8-39.3-21.7-4.8-16.8 4.9-34.4 21.7-39.3z"
          />
        </g>
        <path
          id="star"
          class="s1"
          d="m550 348.9l0.2 5.9c0.1 0.7 0.3 1.5 0.7 2.1l3.4 4.8c1.8 2.5 0 6-3.1 6.2l-5.8 0.2c-0.8 0-1.5 0.3-2.1 0.7l-4.8 3.3c-2.6 1.8-6.1 0.1-6.2-3l-0.2-5.9c0-0.7-0.3-1.5-0.7-2.1l-3.4-4.8c-1.7-2.5 0-6 3.1-6.2l5.9-0.2c0.7 0 1.4-0.3 2-0.7l4.9-3.3c2.5-1.8 6-0.1 6.1 3z"
        />
        <path
          id="star"
          class="s1"
          d="m140.6 241.3l4.9 3.2c0.6 0.4 1.4 0.6 2.1 0.6l5.9 0.1c3.1 0.1 4.9 3.5 3.2 6.1l-3.2 4.9c-0.4 0.6-0.6 1.3-0.6 2.1l-0.1 5.9c-0.1 3.1-3.5 4.9-6.1 3.2l-4.9-3.2c-0.6-0.4-1.3-0.7-2.1-0.7h-5.9c-3.1-0.1-4.9-3.5-3.2-6.1l3.2-4.9c0.4-0.6 0.7-1.4 0.7-2.1v-5.9c0.1-3.1 3.5-4.9 6.1-3.2z"
        />
        <path
          id="star"
          class="s1"
          d="m688 200.7l3.7 2.4c0.5 0.3 1 0.5 1.6 0.5h4.4c2.3 0.1 3.7 2.6 2.4 4.6l-2.4 3.7c-0.3 0.4-0.5 1-0.5 1.6v4.4c0 2.3-2.6 3.7-4.6 2.4l-3.7-2.4c-0.4-0.3-1-0.5-1.5-0.5l-4.5-0.1c-2.3 0-3.7-2.6-2.4-4.5l2.4-3.7c0.4-0.5 0.5-1 0.5-1.6l0.1-4.4c0-2.3 2.6-3.7 4.5-2.4z"
        />
        <path
          id="star"
          class="s25"
          d="m95.8 110.9l3.3 3c0.4 0.4 0.9 0.7 1.4 0.7l4.4 0.8c2.3 0.4 3.2 3.2 1.6 4.9l-3 3.3c-0.3 0.4-0.6 0.9-0.7 1.4l-0.8 4.4c-0.4 2.3-3.2 3.2-4.9 1.6l-3.2-3c-0.4-0.3-0.9-0.6-1.5-0.7l-4.3-0.8c-2.3-0.4-3.3-3.2-1.7-4.9l3-3.2c0.4-0.4 0.7-0.9 0.8-1.5l0.7-4.3c0.4-2.3 3.2-3.3 4.9-1.7z"
        />
        <path id="planet" class="s25" d="m275.3 272c0 4.4-3.5 7.9-7.9 7.9-4.4 0-7.9-3.5-7.9-7.9 0-4.4 3.5-7.9 7.9-7.9 4.4 0 7.9 3.5 7.9 7.9z" />
        <path
          id="planet"
          class="s7"
          d="m771.3 288.2c0 9-7.2 16.2-16.2 16.2-9 0-16.2-7.2-16.2-16.2 0-8.9 7.2-16.2 16.2-16.2 9 0 16.2 7.3 16.2 16.2z"
        />
        <path id="planet" class="s26" d="m85 435.1c0 9-7.2 16.3-16.2 16.3-9 0-16.2-7.3-16.2-16.3 0-8.9 7.2-16.2 16.2-16.2 9 0 16.2 7.3 16.2 16.2z" />
        <path
          id="planet"
          class="s27"
          d="m764.2 574.4c-3.5 14-17.6 22.5-31.6 19-14-3.4-22.5-17.6-19.1-31.6 3.5-14 17.7-22.5 31.7-19 14 3.4 22.5 17.6 19 31.6z"
        />
        <path id="planet" class="s28" d="m633.5 276.3c-0.9 3.7-4.6 5.9-8.3 5-3.6-0.9-5.9-4.6-4.9-8.3 0.9-3.6 4.6-5.9 8.2-5 3.7 1 5.9 4.7 5 8.3z" />
        <path id="planet" class="s10" d="m471.7 436c-0.9 3.7-4.6 5.9-8.3 5-3.6-0.9-5.8-4.6-4.9-8.3 0.9-3.6 4.6-5.9 8.2-5 3.7 1 5.9 4.7 5 8.3z" />
        <path id="planet" class="s10" d="m269.8 326c-0.9 3.7-4.6 5.9-8.3 5-3.6-0.9-5.8-4.6-4.9-8.3 0.9-3.6 4.6-5.9 8.2-5 3.7 1 5.9 4.7 5 8.3z" />
        <path id="planet" class="s10" d="m561.6 524.1c-0.9 3.7-4.6 5.9-8.2 5-3.7-0.9-5.9-4.6-5-8.2 0.9-3.7 4.6-5.9 8.2-5 3.7 0.9 5.9 4.6 5 8.2z" />
        <path id="planet" class="s10" d="m387.6 292.1c-0.9 3.7-4.6 5.9-8.2 5-3.7-0.9-5.9-4.6-5-8.3 0.9-3.6 4.6-5.8 8.2-4.9 3.7 0.9 5.9 4.6 5 8.2z" />
      </g>
    </svg>
  </div>
</template>

<style lang="scss">
.navigation-illustration {
  svg {
    vertical-align: middle;
  }

  .s1 {
    fill: color('main-light');
  }
  .s2 {
    fill: color('main-lighter');
  }
  .s3 {
    fill: color('main-lightest');
  }
  .s4 {
    fill: #d1d1d1;
  }
  .s5 {
    fill: #be1e2d;
  }
  .s6 {
    fill: #ffe0b2;
  }
  .s7 {
    fill: color('neutral-darkest');
  }
  .s8 {
    fill: #bdbdbd;
  }
  .s9 {
    fill: #b0b0b0;
  }
  .s10 {
    fill: color('neutral-darkest');
  }
  .s11 {
    fill: #e0e1e1;
  }
  .s13 {
    fill: #202020;
  }
  .s14 {
    fill: #891523;
  }
  .s15 {
    fill: #222222;
  }
  .s16 {
    fill: #e6e7e8;
  }
  .s17 {
    fill: #d8d8d8;
  }
  .s18 {
    fill: #231f20;
  }
  .s19 {
    fill: #eacba2;
  }
  .s20 {
    fill: #eab6a2;
  }
  .s21 {
    fill: #8b5e3c;
  }
  .s22 {
    fill: color('main-darkest');
  }
  .s23 {
    fill: color('main-darker');
  }
  .s24 {
    fill: #e5c08f;
  }
  .s25 {
    fill: color('neutral');
  }
  .s26 {
    fill: color('neutral-dark');
  }
  .s27 {
    fill: color('neutral-darkest');
  }
  .s28 {
    fill: color('neutral-light');
  }
}
</style>
